import menu from './menu'
import table from './table'

export default {
    title: 'E-Mails',

    mail: 'E-Mail',

    id: 'ID',
    mail_template_id: 'Vorlage-ID',
    resent_mail_id: 'ID der erneut versendeten E-Mail',
    recipient: 'Empfänger',
    subject: 'Betreff',
    body: 'Text',
    bodyDescription: 'Der Text wird hier unformatiert dargestellt',
    custom_text: 'Benutzerdefinierter Text',
    status: 'Status',
    message_id: 'Nachrichten-ID',
    mail_data: 'E-Mail-Daten',
    mailTemplate: 'E-Mailvorlage',
    mailTemplateType: 'Typ',
    mailTemplateName: 'Name',
    created_at: 'Erstellt am',
    updated_at: 'Aktualisiert am',

    statuses: {
        pending: 'Versand ausstehend',
        sent: 'Versandt',
        delivered: 'Zugestellt',
        failed: 'Fehlgeschlagen',
    },

    brevoReason: 'Grund',
    brevoClickedLink: 'Link',

    failedAt: 'Fehlgeschlagen am',
    failedEvent: 'Fehlgeschlagen wegen',

    resendMail: 'E-Mail erneut senden',
    resendMailDescription: 'Möchten Sie die E-Mail erneut senden?',
    resendMailSuccess: 'E-Mail wurde erneut gesendet',
    resendMailFailed: 'E-Mail konnte nicht erneut gesendet werden',

    mailResent: 'E-Mail wurde bereits erneut verschickt',
    mailResentDescription: 'Diese E-Mail wurde bereits erneut verschickt',
    openResentMail: 'Zur neuen E-Mail',

    menu,
    table,
}