import { default as indexTjqSau0iaNMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/auth/index.vue?macro=true";
import { default as indexVmRRS3dvmQMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/auth/social/[organisation]/[provider]/index.vue?macro=true";
import { default as indexFfavvbCyXQMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/index.vue?macro=true";
import { default as organisationBaseDatar7nEQ5JKfFMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as _91id_93pQAQcVGWdjMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/organisations/[id].vue?macro=true";
import { default as indexmc5tSxBK70Meta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/organisations/index.vue?macro=true";
import { default as organisationstoSRXsI4gOMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/organisations.vue?macro=true";
import { default as dungeonYVwj6Zs4uhMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/secret/index/dungeon.vue?macro=true";
import { default as inputs104PTJHe7YMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/secret/index/inputs.vue?macro=true";
import { default as indexxywrCdEEMqMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/secret/index.vue?macro=true";
import { default as languagevRnoHpRArzMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/settings/language.vue?macro=true";
import { default as _91id_93H1seW3CL9dMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/settings/loginProviders/[id].vue?macro=true";
import { default as indexo7qog4quTOMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/settings/loginProviders/index.vue?macro=true";
import { default as mailActivities3MSheGnPCEMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/settings/mails/[id]/mailActivities.vue?macro=true";
import { default as mailBaseData036bZ8J1SzMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/settings/mails/[id]/mailBaseData.vue?macro=true";
import { default as _91id_93RSXRHDOLkEMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/settings/mails/[id].vue?macro=true";
import { default as indexkFPlBDJZM0Meta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/settings/mails/index.vue?macro=true";
import { default as _91_46_46_46slug_93ArctMNRfhOMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/settings/mailTemplates/[name]/[...slug].vue?macro=true";
import { default as indexvYR1PvjOvzMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/settings/mailTemplates/index.vue?macro=true";
import { default as settingsseIcb1lEKPMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/settings.vue?macro=true";
import { default as userActivities2gNy3RugxlMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDataWGQJ6oXvIJMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/users/[id]/userBaseData.vue?macro=true";
import { default as userCommunicationsPLSVqc4ZNAMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/users/[id]/userCommunications.vue?macro=true";
import { default as _91id_937SXm5LEDieMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/users/[id].vue?macro=true";
import { default as indexNLwnvO1vnAMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/users/index.vue?macro=true";
import { default as usersnt3TlEm6VxMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/users.vue?macro=true";
import { default as _91_46_46_46slug_93nACLZK6PJiMeta } from "/home/forge/base.farbcode.net/releases/20250403120728/client/pages/wiki/[...slug].vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    meta: indexTjqSau0iaNMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-social-organisation-provider",
    path: "/auth/social/:organisation()/:provider()",
    meta: indexVmRRS3dvmQMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/auth/social/[organisation]/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: organisationstoSRXsI4gOMeta?.name,
    path: "/organisations",
    meta: organisationstoSRXsI4gOMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/organisations.vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id",
    path: ":id()",
    meta: _91id_93pQAQcVGWdjMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/organisations/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id-organisationBaseData",
    path: "organisationBaseData",
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organisations",
    path: "",
    meta: indexmc5tSxBK70Meta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/organisations/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "secret",
    path: "/secret",
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/secret/index.vue").then(m => m.default || m),
    children: [
  {
    name: "secret-index-dungeon",
    path: "dungeon",
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/secret/index/dungeon.vue").then(m => m.default || m)
  },
  {
    name: "secret-index-inputs",
    path: "inputs",
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/secret/index/inputs.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsseIcb1lEKPMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-language",
    path: "language",
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/settings/language.vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders-id",
    path: "loginProviders/:id()",
    meta: _91id_93H1seW3CL9dMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders",
    path: "loginProviders",
    meta: indexo7qog4quTOMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/settings/loginProviders/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-mails-id",
    path: "mails/:id()",
    meta: _91id_93RSXRHDOLkEMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/settings/mails/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "settings-mails-id-mailActivities",
    path: "mailActivities",
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/settings/mails/[id]/mailActivities.vue").then(m => m.default || m)
  },
  {
    name: "settings-mails-id-mailBaseData",
    path: "mailBaseData",
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/settings/mails/[id]/mailBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings-mails",
    path: "mails",
    meta: indexkFPlBDJZM0Meta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/settings/mails/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates-name-slug",
    path: "mailTemplates/:name()/:slug(.*)*",
    meta: _91_46_46_46slug_93ArctMNRfhOMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/settings/mailTemplates/[name]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates",
    path: "mailTemplates",
    meta: indexvYR1PvjOvzMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/settings/mailTemplates/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: usersnt3TlEm6VxMeta?.name,
    path: "/users",
    meta: usersnt3TlEm6VxMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/users.vue").then(m => m.default || m),
    children: [
  {
    name: "users-id",
    path: ":id()",
    meta: _91id_937SXm5LEDieMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-userActivities",
    path: "userActivities",
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userBaseData",
    path: "userBaseData",
    meta: userBaseDataWGQJ6oXvIJMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/users/[id]/userBaseData.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userCommunications",
    path: "userCommunications",
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/users/[id]/userCommunications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "",
    meta: indexNLwnvO1vnAMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "wiki-slug",
    path: "/wiki/:slug(.*)*",
    meta: _91_46_46_46slug_93nACLZK6PJiMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250403120728/client/pages/wiki/[...slug].vue").then(m => m.default || m)
  }
]