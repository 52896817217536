export default {
    andMore: "und {count} weitere|und ein weiterer|und {count} weitere",

    general: "Etwas ist schief gelaufen.",
    internalError: "Es liegt serverseitig ein Fehler vor.",
    badRequest: "Die Anfrage enthält ungültige Daten.",
    unauthorized: "Dies Aktion ist nicht berechtigt.",
    notFound: "{model} existiert nicht.",
    forbidden: "Sie haben keine Berechtigung, diese Seite aufzurufen.",
    conflict: "Es gab einen Konflikt bei der Verarbeitung Ihrer Anfrage.",
    notAcceptable: "Ihre Anfrage konnte nicht verarbeitet werden.",
    invalid: "Ihre Anfrage enthält ungültige Daten.",
    failedDependency: "Ihre Anfrage konnte nicht verarbeitet werden.",
    tooManyRequests: "Zu viele Anfragen.",

    generalMessage: "Es ist ein unbekannter Fehler aufgetreten.",
    internalErrorMessage: "Es gab einen unbekannten Fehler auf dem Server.",
    badRequestMessage: "Die von Ihnen gesendeten Daten sind ungültig.",
    unauthorizedMessage: "Sie sind nicht berechtigt, diese Daten abzurufen oder diese Aktion auszuführen.",
    notFoundMessage: "Das von Ihnen angefragten Daten zu {model} existieren nicht.",
    forbiddenMessage: "Sie sind entweder nicht angemeldet oder durch Ihre Rolle nicht berechtigt, diese Aktion auszuführen.",
    conflictMessage: "Die von Ihnen gesendeten Daten verursachen einen Konflikt in der Bearbeitung.",
    notAcceptableMessage: "Die von Ihnen gesendeten Daten sind nicht akzeptabel.",
    invalidMessage: "Die von Ihnen gesendeten Daten sind ungültig. Überprüfen Sie bitte Ihre Eingaben.",
    failedDependencyMessage: "Bei der Verarbeitung Ihrer Anfrage kam es zu Fehlern in Abhängigkeiten der Daten.",
    tooManyRequestsMessage: "Sie haben zu viele Anfragen in einem kurzen Zeitraum gesendet. Bitte versuchen Sie es später erneut."
}