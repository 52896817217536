import {$fetch, FetchError} from "ofetch"
import type {FetchContext, FetchOptions, FetchResponse, MappedResponseType} from "ofetch"
import {useNotification} from "../composables/useNotification"
import {useAuthStore} from "../store/auth"

const CSRF_COOKIE = "XSRF-TOKEN"
const CSRF_HEADER = "X-XSRF-TOKEN"

const SERVER_ERROR = 500
const UNAUTHORIZED = 401
const PAGE_EXPIRED = 419

const DEFAULT_THROTTLE = 10000

const STATUSES_TO_THROTTLE = [401, 429]
const STATUSES_TO_IGNORE = [401]

const STATUS_KEYS: Record<number, string> = {
    200: 'success',
    201: 'created',
    202: 'accepted',
    204: 'noContent',
    500: 'internalError',
    400: 'badRequest',
    401: 'unauthorized',
    404: 'notFound',
    403: 'forbidden',
    409: 'conflict',
    406: 'notAcceptable',
    422: 'invalid',
    424: 'failedDependency',
    429: 'tooManyRequests',
}

// Unfortunately could not import these types from ohmyfetch, so copied them here
interface ResponseMap {
    blob: Blob;
    text: string;
    arrayBuffer: ArrayBuffer;
}

type ResponseType = keyof ResponseMap | "json";
// end of copied types

type LarafetchOptions<R extends ResponseType> = FetchOptions<R> & {
    redirectIfNotAuthenticated?: boolean;
    redirectIfNotVerified?: boolean;
    formDataRequest?: boolean;
    rawResponse?: boolean;
};

type LaraFetchMethod = <T, R extends ResponseType = "json">(path: RequestInfo, options?: LarafetchOptions<R>) => Promise<T>;

type LaraFetchMethods = {
    get: LaraFetchMethod;
    post: LaraFetchMethod;
    put: LaraFetchMethod;
    patch: LaraFetchMethod;
    delete: LaraFetchMethod;
};

export const $larafetch = _larafetch
export const $lara = createHelperMethods()

type IsError<T> = {
    error: T
}

type NeverError<T> = T & {
    error?: never
}

type MaybeError<E, D> = IsError<E> | NeverError<D>

async function _larafetch<T, R extends ResponseType = "json">(
    path: RequestInfo,
    {
        redirectIfNotAuthenticated = true,
        formDataRequest = false,
        rawResponse = false,
        ...options
    }: LarafetchOptions<R> = {}
): Promise<MaybeError<FetchError, FetchResponse<MappedResponseType<R, T>>>> {
    const {backendUrl, frontendUrl} = useRuntimeConfig().public
    const router = useRouter()
    const {$i18n} = useNuxtApp()

    const token = await _getCsrfToken(options)
    const headers: any = _getHeaders(options, token, formDataRequest, frontendUrl)

    try {
        if (options.params) {
            Object.keys(options.params).forEach((key) => {
                if (options.params && Array.isArray(options.params[key])) {
                    options.params[key] = JSON.stringify(options.params[key])
                }
            })
        }

        const requestOptions = {
            baseURL: backendUrl,
            ...options,
            headers,
            credentials: 'include' as RequestCredentials,
            onResponse: (context: FetchContext & {
                response: FetchResponse<R>
            }): Promise<void> | void => _handleResponse(context.response, options.method)
        }

        return rawResponse ? await $fetch.raw(path, requestOptions) : await $fetch(path, requestOptions)
    } catch (error) {
        if (!(error instanceof FetchError)) throw error

        const status = error.response?.status ?? -1

        // @ts-ignore
        if (redirectIfNotAuthenticated && [UNAUTHORIZED, PAGE_EXPIRED].includes(status) && !router.currentRoute?.value?.name?.startsWith("auth")) {
            if(!_throttled(error)) useNotification().error($i18n.t("auth.sessionTimeout"), $i18n.t("auth.pleaseLoginAgain"))
            useAuthStore().resetAuthData()
            await router.push("/auth")
        }

        if (useRuntimeConfig().public.features.maybeError) {
            errorMessages(status, error, $i18n)
            return {error}
        }

        if ([500].includes(status)) {
            useNotification().error($i18n.t("serverError"), $i18n.t("serverErrorSupport"))
        }

        throw error
    }
}

function createHelperMethods(): LaraFetchMethods {
    const helperMethods: any = {}

    for (const method of ["get", "post", "put", "patch", "delete"]) {
        helperMethods[method] = async <T, R extends ResponseType = "json">(path: RequestInfo, options?: LarafetchOptions<R>) => {
            return _larafetch<T, R>(path, {
                ...options,
                method
            })
        }
    }
    return helperMethods
}

/**
 * Handle error messages based on status code
 */
function errorMessages(status: number, error: FetchError, $i18n: any) {

    // default error messages
    if (STATUSES_TO_IGNORE.includes(status)) return
    // if (status === UNAUTHORIZED) return useNotification().error($i18n.t("auth.sessionTimeout"), $i18n.t("auth.pleaseLoginAgain"))
    if (status === SERVER_ERROR) return useNotification().error($i18n.t("serverError"), $i18n.t("serverErrorSupport"))
    if (_throttled(error)) return

    // TODO maybe make exceptions for some status codes to never show error message

    const statusKey = STATUS_KEYS[status] ?? "general"
    const message = error.response?._data?.message ?? (statusKey + "Message")
    // errors is object with keys as field names and values as error messages
    const errors = Object.values(error.response?._data?.errors ?? {})
    const context = error.response?._data

    let errorsAsString = ""

    if (errors.length > 0) {
        if (Array.isArray(errors[0]) && errors[0].length > 0) {
            errorsAsString = errors[0][0]
        } else {
            errorsAsString = errors[0] as string
        }
    }

    if (errors.length > 1) {
        errorsAsString += " (" + $i18n.t("errors.andMore", errors.length - 1) + ")"
    }

    const errorMessage = errorsAsString ? errorsAsString : $i18n.te('errors.' + message) ? $i18n.t('errors.' + message, context) : message
    useNotification().warning($i18n.t("errors." + statusKey), errorMessage)
}

async function _getCsrfToken(options: FetchOptions) {
    let token = useCookie(CSRF_COOKIE).value

    // on client initiate a csrf request and get it from the cookie set by laravel
    if (
        !token && import.meta.client &&
        ["post", "delete", "put", "patch"].includes(
            options?.method?.toLowerCase() ?? ""
        )
    ) {
        await _initCsrf()
        // cannot use nuxt composables such as useCookie after an async operation: https://github.com/nuxt/framework/issues/5238
        token = _getCookie(CSRF_COOKIE)
    }

    return token
}

function _getHeaders(options: FetchOptions, token: string | null | undefined, formDataRequest: boolean, frontendUrl: string) {
    let headers: any = {
        ...options?.headers,
        ...(token && {[CSRF_HEADER]: token}),
        accept: "application/json",
    }

    // let header be set automatically for form data requests
    if (!formDataRequest) headers["content-type"] = "application/json"

    if (import.meta.server) {
        headers = {
            ...headers,
            ...useRequestHeaders(["cookie"]),
            referer: frontendUrl,
        }
    }

    return headers
}

function _handleResponse(response: any, method: string | undefined = 'get') {
    const authStore = useAuthStore()
    const farbcode_version = response.headers.get('farbcode-version')
    if (!!farbcode_version && farbcode_version !== authStore.remoteVersion && method === 'get')
        authStore.setRemoteVersion(farbcode_version)
    return response
}

async function _initCsrf() {
    const {backendUrl} = useRuntimeConfig().public

    await $fetch("/app/sanctum/csrf-cookie", {
        baseURL: backendUrl,
        credentials: "include",
    })
}

// https://github.com/axios/axios/blob/bdf493cf8b84eb3e3440e72d5725ba0f138e0451/lib/helpers/cookies.js
function _getCookie(name: string) {
    const match = document.cookie.match(
        new RegExp("(^|;\\s*)(" + name + ")=([^;]*)")
    )
    return match ? decodeURIComponent(match[3]) : null
}

/**
 * Throttle some error statuses to prevent multiple notifications
 */
function _throttled(error: FetchError) {
    if (!STATUSES_TO_THROTTLE.includes(error.response?.status ?? -1)) return false

    const dayjs = useDayjs()
    // check state when the error occurred last and if time has passed since then
    const lastError = useState<string|null>('status_' + error.response?.status + '_throttle', () => null)
    const isThrottled = !!lastError.value && (Math.abs(dayjs(lastError.value).diff()) < DEFAULT_THROTTLE)

    if (isThrottled) return true
    lastError.value = dayjs().toISOString()
    return false
}
